import { List, Stack, styled } from "@mui/material";

import { calculateBorder } from "../../../../../../utilities";

//export const NavAppBar = styled(AppBar)({ component: "nav" }, ``);

export const NavBarWrapper = styled("nav")(
  (props) => ({
    gridColumn: "2 / 3",

    // [props.theme.breakpoints.up("sm")]: {
    //   paddingBottom: "10px",
    // },
  }),
  (props) => `
  
    height: 100%;
    ${calculateBorder("orange", 5)}
  `
);

export const NavList = styled(List)((props) => ({
  display: "flex",
  // flexWrap: "wrap",
  // gap: "1rem",

  margin: "0 0 10px 0",
  padding: "0 5px 0 5px",
  listStyle: "none",

  height: "inherit",
  alignItems: "center",
  justifyContent: "flex-start",

  [props.theme.breakpoints.only("sm")]: {
    display: "none",
  },
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const NavListItem = styled("li")(
  (props) => ({
    flexGrow: 1,
    flexShrink: 1,

    justifyContent: "center",
    fontSize: "var(--font-size-md)",
    color: "var(--color-white)",
    fontFamily: "var(--font-quicksand)",
    // textShadow:
    //   "1px 0 0 rgba(0, 0, 0, 0.35), 0 1px 0 rgba(0, 0, 0, 0.35), -1px 0 0 rgba(0, 0, 0, 0.35), 0 -1px 0 rgba(0, 0, 0, 0.35)",
  }),
  `
    ${calculateBorder("yellow", 5)}
    `
);

export const NavDivider = styled("div")`
  height: 28px;
  width: 1px;
  position: relative;
  border-right: 1px solid var(--color-gray-100);
  box-sizing: border-box;
`;
