import { List, Stack, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";

import { calculateBorder } from "../../../../utilities";

export const HeaderWrapper = styled(AppBar)(
  (props) => ({
    position: "fixed",
    // minWidth: "300px",
    width: `100svw`,
    minHeight: "50px",
    zIndex: 100,

    display: "grid", // flex
    height: 115,
    //padding: 5,

    // [props.theme.breakpoints.up("md")]: {
    //   gridTemplateColumns: "1fr minmax(70svw, 0.85fr) 1fr",
    // },
    // [props.theme.breakpoints.down("sm")]: {
    //   gridTemplateColumns: "0 100svw 0",
    //   height: 85,
    // },

    [props.theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
    },
    [props.theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
    },
    [props.theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
      height: 85,
    },
    [props.theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "0 100svw 0",
      height: 85,
      display: "block",
    },

    alignItems: "center",
    justifyContent: "center",
    color: "black",
  }),
  (props) =>
    `
  //   background: linear-gradient(rgba(32, 36, 39, 0.35), rgba(32, 36, 39, 0.35)),
  background: var(--color-white);
    rgba(216, 160, 133, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
        ${calculateBorder("blue", 5)}
      `
);

export const HeaderInformation = styled(Stack)(
  (props) => ({
    //padding: "5px 20px 5px 20px",
    alignItems: "center",
    gap: 1,
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    gridColumn: "2/3",

    [props.theme.breakpoints.only("sm")]: {
      display: "none",
    },
    [props.theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }),
  ``
);

// const HeaderWrapper = styled("div")(
//     {
//       gridColumn: "2 / 3",
//     },
//     ``
//   );
