import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { Theme, useMediaQuery } from "@mui/material";
import { PaletteMode } from "@mui/material";

import { Debug } from "../../utilities/debug-utilities";
import UIContext from "./UIContext";
import { initialState } from "./UIContextProvider.constants";
import { UIState } from "./UIContextProvider.types";

const UIContextProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<UIState>(initialState);

  const prefersDarkMode = process.env.GATSBY_THEME_ENABLE_DARK_MODE
    ? useMediaQuery("(prefers-color-scheme: dark)")
    : false;

  const [mode, setMode] = React.useState<PaletteMode>(
    prefersDarkMode ? "dark" : "light"
  );

  const isSmallScreenQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const toggleColorMode = () => {
    setState((prev) => ({
      ...prev,
      themeMode: prev.themeMode === "light" ? "dark" : "light",
    }));
  };

  /**
   * useMediaQuery
   *
   */
  const mq_xs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const mq_sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"));
  const mq_md = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"));
  const mq_lg = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));
  const mq_xl = useMediaQuery((theme: Theme) => theme.breakpoints.only("xl"));

  /**
   * getBreakPointName
   *
   */
  const getBreakPointName = () => {
    if (mq_xs) {
      return "xs";
    }
    if (mq_sm) {
      return "sm";
    }
    if (mq_md) {
      return "md";
    }
    if (mq_lg) {
      return "lg";
    }
    if (mq_xl) {
      return "xl";
    }
  };

  const currentBreakpoint = useMemo(() => {
    Debug("Current Breakpoint:", getBreakPointName());
  }, [getBreakPointName]);

  const providerValue = useMemo(
    () => ({
      isSmallScreen: isSmallScreenQuery,
      themeMode: state.themeMode,
      toggleColorMode: toggleColorMode,
    }),
    [state, isSmallScreenQuery]
  );

  return (
    <UIContext.Provider value={providerValue}>{children}</UIContext.Provider>
  );
};

export default UIContextProvider;
