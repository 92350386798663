export const Constants = {
  PhoneNumber: {
    Display: "(336)-465-5609",
    Value: "+1-336-465-5609",
  },
  Address: {
    Street1: "208 W Center Street",
    Street2: "",
    Unit: "Suite D",
    City: "Lexington",
    State: {
      Long: "North Carolina",
      Short: "NC",
    },
    ZipCode: "27292",
  },
};

export const FormattedAddress = () => {
  return `${Constants.Address.Street1} ${Constants.Address.Unit}, ${Constants.Address.City}, ${Constants.Address.State.Short} ${Constants.Address.ZipCode}`;
};
