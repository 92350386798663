import React, { ErrorInfo, useContext } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MaterialThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import UIContext from "./contexts/ui/UIContext";
import UIContextProvider from "./contexts/ui/UIContextProvider";
import { defaultTheme, materialTheme } from "./styles/theme";

export const mediaQueries = (
  key: keyof typeof defaultTheme.breakpoints.values
) => {
  return (style: TemplateStringsArray | String) =>
    `@media (min-width: ${defaultTheme.breakpoints.values[key]}px) { ${style} }`;
};

interface Props {
  children: React.ReactNode;
  location?: Location;
}

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const logError = (error: Error, info: ErrorInfo) => {
  // Do something with the error, e.g. log to an external API
  console.error(error.message);
};

const App = ({ children, location }: Props) => {
  const { themeMode } = useContext(UIContext);

  // Further work needed for this, we need the Context to wrap the Themes
  // so might need to create a new one instead
  const theme = React.useMemo(() => {
    return materialTheme(themeMode);
  }, [themeMode, UIContext]);

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <MaterialThemeProvider theme={theme}>
          {/* <ThemeProvider theme={theme}> */}
          {/* <APIProvider
            apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
            version="beta"
          > */}
          <UIContextProvider>{children}</UIContextProvider>
          {/* </APIProvider> */}
          {/* </ThemeProvider> */}
        </MaterialThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

export default App;
