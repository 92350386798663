export const calculateGrid = (
  value: number,
  width: string,
  unit: "svw" | "dvw" | "svh" | "dvh"
) => {
  const widthMeasurement = parseInt(width);
  const actualWidth = widthMeasurement / value;

  let rowStyle = "";

  for (let i = 0; i < value; i++) {
    rowStyle += `${actualWidth}${unit} `;
  }

  return rowStyle;
};

export const calculateBorder = (
  color: string,
  width: number,
  enabled?: boolean
) => {
  var calculate = process.env.NODE_ENV === "development" && (enabled || false);
  if (calculate)
    return `box-shadow: inset -${width}px 0 0 ${color}, inset 0 -${width}px 0 ${color}, inset ${width}px 0 0 ${color}, inset 0 ${width}px 0 ${color};`;

  return "";
};

export const isLastElement = (array: [], index: number) => {
  return array.length - 1 === index;
};
