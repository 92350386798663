import React from "react";

import { PaletteMode } from "@mui/material";
import { createTheme as createMaterialTheme } from "@mui/material/styles";

// Look into https://mui.com/system/experimental-api/css-theme-variables/
export const defaultTheme = createMaterialTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 360, //640,
      md: 640, //900,
      lg: 900, //1200,
      xl: 1200, //1536,
    },
  },
  typography: {
    // Sets all of the font to default to quicksand for MUI
    fontFamily: "var(--font-quicksand)",
    h1: {
      fontFamily: "var(--font-amsterdam-four)",
      fontSize: "48px",
      lineHeight: "normal",
    },
    h4: {
      fontWeight: 700,
      fontSize: "var(--font-size-xl)",
      fontFamily: "var(--font-bebas-neue)",
      lineHeight: "normal",
    },
    subtitle1: {
      fontStyle: "italic",
      fontFamily: "var(--font-quicksand)",
    },
    subtitle2: {
      fontWeight: 700,
      fontStyle: "italic",
      fontFamily: "var(--font-quicksand)",
      fontSize: "var(--font-size-2xs)",
    },
    header: {
      fontWeight: 700,
      fontSize: "var(--font-size-2xs)",
      fontFamily: "var(--font-quicksand)",
      lineHeight: "normal",
    },
  },
  components: {
    sidebar: {
      width: "400px",
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "var(--color-desert-sun)",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "var(--color-desert-sun)",
        },
        scrollButtons: {
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
  },
});

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.typography
export const materialTheme = (mode: PaletteMode = "light") => {
  return createMaterialTheme(
    {
      palette: {
        mode: mode,
        salmon: defaultTheme.palette.augmentColor({
          color: {
            main: "#d8a085",
          },
          name: "salmon",
        }),
      },
    },
    defaultTheme
  );
};
