import * as React from "react";

import type { GatsbyBrowser } from "gatsby";

import App from "./src/app";
import Layout from "./src/components/layout";
import "./src/styles/global.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <App>{element}</App>;
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = (
  props
) => {
  if (props.pathname != props.prevRouterProps?.location.pathname) {
    if (props.routerProps.location.hash) {
      document
        .getElementById(props.routerProps.location.hash.replace("#", ""))
        ?.scrollIntoView({
          block: "start",
          behavior: "instant",
        });
    } else {
      document
        .getElementById("main-container")
        ?.scrollTo({ top: 0, behavior: "instant" });
    }
  }

  return false;
};
