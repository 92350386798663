import React, { FunctionComponent, useContext, useEffect } from "react";

import { Box, Divider, Slide, Stack, Typography, styled } from "@mui/material";

import {
  LocalPhoneTwoToneIcon,
  PlaceTwoToneIcon,
} from "../../../../assets/icons";
import UIContext from "../../../../contexts/ui/UIContext";
import { Constants, FormattedAddress } from "../../../../data/constants";
import { SocialMedia } from "../../../../data/routes";
import useGetScrollTrigger from "../../../../hooks/useGetScrollTrigger";
import { Debug } from "../../../../utilities/debug-utilities";
import { SocialMediaIcon } from "../../../icons";
import { LayoutBaseProps } from "../../layout.types";
import NavBar from "./components/navbar";
import { HeaderInformation, HeaderWrapper } from "./header.styled";

const SocialMediaWrapper = styled(Box)(
  {
    display: "flex",
  },
  ``
);

interface HeaderProps extends LayoutBaseProps {}

const Information = ({ text, icon, link }: InformationProps) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {icon && icon}
      <Typography variant="header">
        {link ? <a href={link}>{text}</a> : <>{text}</>}
      </Typography>
    </Stack>
  );
};

interface InformationProps {
  text: string;
  icon?: React.ReactNode;
  link?: string;
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isSmallScreen } = useContext(UIContext);
  const formattedAddress = FormattedAddress();

  const handleDrawerToggle = (state?: boolean) => {
    setMobileOpen((prevState) => state ?? !prevState);
  };

  useEffect(() => {
    if (!isSmallScreen && mobileOpen) {
      setMobileOpen(false);
    }
  }, [isSmallScreen]);

  Debug("isSmallScreen", isSmallScreen);

  const trigger = useGetScrollTrigger(100);

  return (
    <>
      <Slide
        appear={false}
        direction="down"
        in={!trigger}
        style={{ gridColumn: "2/3" }}
      >
        <HeaderWrapper>
          {/* {!isSmallScreen && (
            <> */}
          <HeaderInformation
            direction={"row"}
            spacing={{ xs: 2, sm: 2, md: 2 }}
          >
            <Information
              text={`${Constants.PhoneNumber.Display}`}
              icon={
                <LocalPhoneTwoToneIcon
                  fontSize="small"
                  style={{
                    alignSelf: "center",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                />
              }
              link={`tel:${Constants.PhoneNumber.Value}`}
            />
            {/* <Information
                  text="info@indierogue.com"
                  icon={
                    <EmailTwoToneIcon
                      fontSize="small"
                      style={{
                        alignSelf: "center",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  link="mailto:info@indierouge.commailto:info@indierouge.com"
                /> */}
            <Information
              text={formattedAddress}
              icon={
                <PlaceTwoToneIcon
                  fontSize="small"
                  style={{
                    alignSelf: "center",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                />
              }
            />
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              gap={0.5}
              style={{ flexGrow: 1, flexShrink: 1, width: "20px" }}
            >
              <SocialMediaWrapper style={{ height: "20px", scale: "0.4" }}>
                {SocialMedia.map((media, index) => {
                  return (
                    media.Path && (
                      <SocialMediaIcon
                        key={index}
                        index={index}
                        label={media.Name}
                        url={media.Path}
                        type={media.Type}
                      />
                      // <IconButton
                      //   key={index}
                      //   aria-label={socialMedia.Name}
                      //   style={{ borderRadius: 5 }}
                      //   onClick={() => {
                      //     window.open(socialMedia.Path, "_blank");
                      //   }}
                      // >
                      //   {socialMedia.Icon}
                      // </IconButton>
                    )
                  );
                })}
              </SocialMediaWrapper>
            </Stack>
          </HeaderInformation>
          <Divider
            component="div"
            variant="middle"
            style={{ margin: "5px 5px 5px 5px", gridColumn: "2/3" }}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          />
          {/* </>
          )} */}
          <NavBar />
        </HeaderWrapper>
      </Slide>
    </>
  );
};

export default Header;
