import { styled } from "@mui/material";

import { calculateBorder } from "../../../../utilities";

export const FooterWrapper = styled("footer")(
  (props) => ({
    background: "var(--color-pinkish-tan)",

    //width: "100svw",
    //overflow: "hidden",

    display: "grid",

    // https://stackoverflow.com/a/68002066/11276870
    boxShadow: "inset rgb(0 0 0 / 25%) 1px 6px 6px -6px",

    //boxShadow: "0px 0px 29.7px 11px rgba(0, 0, 0, 0.25)",

    //marginTop: "25px",
    padding: "10px 0 0 0",

    [props.theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
    },
    [props.theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
    },
    [props.theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "0 100svw 0",
    },
  }),
  `    
        ${calculateBorder("green", 5)}
      `
);
