import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { PropsWithStyle } from "../../types/shared.types";

interface LogoProps extends PropsWithStyle {}

const query = graphql`
  {
    file(absolutePath: {}, name: { eq: "logo" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;

const Logo: React.FunctionComponent<LogoProps> = ({ style }: LogoProps) => {
  const logo = useStaticQuery(query);

  return (
    <GatsbyImage
      image={logo?.file.childImageSharp.gatsbyImageData}
      alt="logo"
      style={style}
      loading="eager"
    />
  );
};

export default Logo;
