import { styled } from "@mui/material";

import { calculateBorder } from "../../utilities";

export const LayoutWrapper = styled("div")(
  (props) => ({
    height: "100dvh",
    width: "100svw",

    // Fixes the height issue for smaller pages
    display: "flex",
    flexDirection: "column",

    // [props.theme.breakpoints.up("sm")]: {
    //   gridTemplateColumns: "1fr minmax(70svw, 0.85fr) 1fr",
    // },
    // [props.theme.breakpoints.down("sm")]: {
    //   gridTemplateColumns: "0 100svw 0",
    // },
    // display: "grid",

    overflow: "auto",
    scrollbarWidth: "none",
  }),
  (props) => `background-color: white
  ${calculateBorder("purple", 5, true)}`
);

export const Body = styled("main")(
  (props) => ({
    className: "item",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,

    //gridRow: "1 / 3",
    gridColumn: "1 / 4",
    width: "100svw",

    marginTop: 115,

    [props.theme.breakpoints.down("md")]: {
      marginTop: 85,
    },
    [props.theme.breakpoints.down("sm")]: {
      marginTop: 85,
    },

    // minHeight: "calc(100dvh - 159px - 11px)",
    //height: "100dvh",

    background: "var(--color-white)",
  }),
  `    
      ${calculateBorder("green", 5)}
    `
);

export const Address = styled("p")(
  {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "var(--font-size-mini)",
    fontFamily: "var(--font-quicksand)",
  },
  ``
);

export const CopyrightWrapper = styled("small")(
  {
    gridColumn: "2/3",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

    padding: "5px",

    fontFamily: "var(--font-quicksand)",
  },
  ``
);

// const Footer = styled("footer")(
//   (props) => ({
//     background: "var(--color-pinkish-tan)",

//     gridColumn: "1 / 4",
//     display: "flex",
//     flexDirection: "column",
//     flexGrow: 1,

//     // [props.theme.breakpoints.up("md")]: {
//     //   gridTemplateColumns:
//     //     "minmax(50px, 200px) minmax(80svw, 1fr) minmax(50px, 200px)",
//     // },
//     // [props.theme.breakpoints.down("md")]: {
//     //   gridTemplateColumns: "0 100svw 0",
//     // },
//   }),
//   `
//       ${calculateBorder("green", 5)}
//     `
// );
