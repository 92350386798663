import React, { createContext } from "react";

import { PaletteMode } from "@mui/material";

type UIContextType = {
  isSmallScreen: boolean;
  themeMode: PaletteMode;
  toggleColorMode: () => void;
};

const defaultValue: UIContextType = {
  isSmallScreen: false,
  themeMode: "light",
  toggleColorMode: () => {},
};

const UIContext = createContext<UIContextType>(defaultValue);

export default UIContext;
