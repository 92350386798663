import React, { useEffect, useState } from "react";

// https://stackoverflow.com/questions/54256521/react-ssr-document-is-not-defined
export const useDocument = () => {
  const [myDocument, setMyDocument] = useState<Document | null>(null);

  useEffect(() => {
    setMyDocument(document);
  }, []);

  return myDocument;
};
