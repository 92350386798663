import React, { useEffect, useState } from "react";

import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import { Divider, Stack, Typography } from "@mui/material";

import { Constants, FormattedAddress } from "../../../../data/constants";
import { SocialMedia } from "../../../../data/routes";
import { SocialMediaIcon } from "../../../icons";
import Logo from "../../../logo/logo.component";
import { CopyrightWrapper } from "../../layout.styled";
import { FooterWrapper } from "./footer.styled";

interface FooterProps {}

function Footer(props: FooterProps) {
  const [originalYear, setOriginalYear] = useState<number>(2024);
  const [currentYear, setCurrentYear] = useState<number>(2024);
  const formattedAddress = FormattedAddress();

  useEffect(() => {
    setOriginalYear(new Date("2024").getFullYear());
    setCurrentYear(new Date().getFullYear());
  }, []);

  const Copyright = () => {
    const yearText =
      originalYear === currentYear
        ? currentYear
        : `${originalYear}-${currentYear}`;

    return (
      <CopyrightWrapper
        sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
      >
        <Typography variant="header">
          &copy; Copyright {yearText} Indie Rouge. All rights reserved.
        </Typography>
      </CopyrightWrapper>
    );
  };

  return (
    <FooterWrapper>
      {/* <Stack
        style={{
          gridColumn: "2/3",
          boxShadow:
            "inset -5px 0 0 green, inset 0 -5px 0 green, inset 5px 0 0 green, inset 0 5px 0 green",
        }}
        direction={{ sm: "column", md: "row" }}
        useFlexGap
        flexWrap="wrap"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            boxShadow:
              "inset -2px 0 0 red, inset 0 -2px 0 red, inset 2px 0 0 red, inset 0 2px 0 red",

            alignItems: "center",
            maxWidth: "50%",
          }}
        >
          <Logo style={{ width: "200px" }} />
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "var(--font-size-mini)",
              fontFamily: "var(--font-quicksand)",
            }}
          >
            208 W Center Street Suite D, Lexington
            <br />
            North Carolina, 27360
          </Typography>
        </div>
        <div
          style={{
            flexGrow: 1,
            boxShadow:
              "inset -2px 0 0 yellow, inset 0 -2px 0 yellow, inset 2px 0 0 yellow, inset 0 2px 0 yellow",
          }}
        >
          cake
        </div>
      </Stack> */}

      <Stack
        spacing={{ xs: 2, sm: 2 }}
        direction={{ sx: "column", sm: "column", md: "row" }}
        useFlexGap
        flexWrap="wrap"
        style={{ gridColumn: "2/3" }}
        sx={{ alignContent: { xs: "center" } }}
      >
        <div style={{ flexGrow: 0.5, display: "flex", alignItems: "center" }}>
          <Logo />
        </div>
        <Stack
          direction={{ xs: "row", md: "row" }}
          useFlexGap
          gap={{ xs: 3, md: 8 }}
          sx={{ justifyContent: { xs: "space-between", md: "flex-end" } }}
          style={{
            // margin: "0 15px 0 15px",
            flexGrow: 1,
            flexShrink: 1,
            flexWrap: "wrap",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              gap: "2.5px",
            }}
          >
            <Typography
              variant="header"
              style={{
                fontSize: "13px",
                fontWeight: "700",
                paddingBottom: "10px",
              }}
            >
              Links
            </Typography>

            {Pages.filter((i) => i.Enabled).map((page, index) => {
              return (
                <Link to={page.Path}>
                  <Typography key={index} variant="header">
                    {page.Name}
                  </Typography>
                </Link>
              );
            })}
          </div> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",

              gap: "2.5px",
            }}
          >
            <Typography
              variant="header"
              style={{
                fontSize: "13px",
                fontWeight: "700",
                paddingBottom: "10px",
              }}
            >
              Contact Us
            </Typography>

            <Stack direction="row" alignItems="center" gap={1}>
              <PlaceIcon fontSize="inherit" />
              <Typography variant="header">{formattedAddress}</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              style={{ flexWrap: "wrap" }}
            >
              <LocalPhoneIcon fontSize="inherit" />
              <Typography variant="header">
                <a href={`tel:${Constants.PhoneNumber.Value}`}>
                  {Constants.PhoneNumber.Display}
                </a>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              style={{ flexWrap: "wrap" }}
            >
              <EmailIcon fontSize="inherit" />
              <Typography variant="header">
                <a href="mailto:info@indierouge.com">info@indierouge.com</a>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              style={{ flexWrap: "wrap" }}
            >
              {SocialMedia.map((media, index) => {
                return (
                  media.Path && (
                    <SocialMediaIcon
                      key={index}
                      index={index}
                      label={media.Name}
                      url={media.Path}
                      type={media.Type}
                      variant="mui"
                      fontSize="inherit"
                      style={{ padding: 0, fill: undefined }}
                    />
                  )
                );
              })}
            </Stack>
          </div>
        </Stack>
      </Stack>

      {/* <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          gridColumn: "2/3",
          justifyContent: "center",
          alignItems: "center",
          padding: "25px",
        }}
        spacing={6}
      >
        <Stack style={{ padding: "25px", alignItems: "center" }}>
          <Logo style={{ width: "250px" }} />
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "var(--font-size-mini)",
              fontFamily: "var(--font-quicksand)",
            }}
          >
            208 W Center Street Suite D, Lexington, North Carolina, 27360
          </Typography>
        </Stack>

        <Stack
          direction={{ xs: "row", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
          }}
        >
          <>
            {SocialMedia.map((media, index) => {
              return (
                media.Path && (
                  <SocialMediaIcon
                    key={index}
                    index={index}
                    label={media.Name}
                    url={media.Path}
                    type={media.Type}
                  />
                )
              );
            })}
          </>
          <>
            {Pages.filter((i) => i.Enabled).map((page, index) => {
              return (
                <Typography
                  key={index}
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "var(--font-size-mini)",
                    fontFamily: "var(--font-quicksand)",
                  }}
                >
                  {page.Name}
                </Typography>
              );
            })}
          </>
        </Stack>
      </Stack> */}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{
          margin: "0 5px 0 5px",
          padding: "5px 0 15px 0",
          gridColumn: "2/3",
        }}
      />
      <Copyright />
    </FooterWrapper>
  );
}

Footer.propTypes = {};

export default Footer;
