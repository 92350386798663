import React, { useEffect, useState } from "react";

import { useScrollTrigger } from "@mui/material";

import { useDocument } from "./useDocument";

export default function useGetScrollTrigger(
  threshold: number = 100,
  disableHysteresis: boolean = false
) {
  const [target, setTarget] = useState<Node | undefined>();
  const doc = useDocument();

  useEffect(() => {
    const newTarget = doc?.getElementById("main-container") as Node;
    setTarget(newTarget);
  }, [doc?.readyState]);

  const trigger = useScrollTrigger({
    target: target,
    disableHysteresis: disableHysteresis,
    threshold: threshold,
  });

  return target && trigger;
}
