import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";

import {
  FacebookIcon as CustomFacebookIcon,
  InstagramIcon as CustomInstagramIcon,
} from "../../assets/icons";
import { SocialMediaType } from "../../types/enums";
import { PropsWithStyle } from "../../types/shared.types";

interface SocialMediaIconProps extends PropsWithStyle {
  index: number;
  label: string;
  url: string;
  type: SocialMediaType;
  fontSize?: "inherit" | "large" | "medium" | "small";
  variant?: "custom" | "mui";
}

const SocialMediaIcon: React.FunctionComponent<SocialMediaIconProps> = ({
  index,
  label,
  url,
  type,
  fontSize,
  variant = "custom",
  style,
}) => {
  const getIcon = () => {
    switch (type) {
      case SocialMediaType.Facebook:
        return variant === "custom" ? (
          <CustomFacebookIcon />
        ) : (
          <FacebookIcon fontSize={fontSize} />
        );
      case SocialMediaType.Instagram:
        return variant === "custom" ? (
          <CustomInstagramIcon />
        ) : (
          <InstagramIcon fontSize={fontSize} />
        );
      default:
        return null;
    }
  };

  const icon = getIcon();

  return (
    <IconButton
      key={index}
      aria-label={label}
      style={{ borderRadius: 5, fontSize: fontSize, ...style }}
      onClick={() => {
        typeof window !== "undefined" && window.open(url, "_blank");
      }}
    >
      {icon && icon}
    </IconButton>
  );
};

export default SocialMediaIcon;
