import React, { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";

import useGetScrollTrigger from "../../../../hooks/useGetScrollTrigger";
import { Debug } from "../../../../utilities/debug-utilities";

enum Direction {
  Up,
  Down,
}

interface ChipProps {}

const Chip: React.FunctionComponent<ChipProps> = () => {
  const trigger = useGetScrollTrigger(200, true);

  const [anchors, setAnchors] = useState<Element[]>();

  let pathname = typeof window !== "undefined" ? window.location.pathname : "";

  useEffect(() => {
    if (document) {
      var results = document.querySelectorAll('[id^="anchor"]');
      setAnchors(Array.from(results));
      Debug("selectors", results);
    }
  }, [pathname]);

  function isInView(el: Element) {
    const box = el.getBoundingClientRect();
    return box.top < window.innerHeight && box.bottom >= 0;
  }

  // https://medium.com/the-coders-guide-to-javascript/smooth-scrolling-anchor-menu-in-reactjs-175030d0bce2
  // Look into using some of this for how we want to find each section

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    direction: Direction
  ) => {
    if (direction === Direction.Up && anchors) {
      document
        .getElementById("main-container")
        ?.scrollTo({ top: 0, behavior: "smooth" });
    } else if (direction === Direction.Down && anchors) {
      let anchor;

      for (const element of anchors) {
        if (!isInView(element)) {
          anchor = element;
          break;
        }
      }

      if (anchor) {
        Debug(anchor);
        anchor.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <Fade in={trigger}>
        <Box
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            handleClick(event, Direction.Up)
          }
          role="presentation"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 2,
          }}
        >
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              // backgroundColor: "var(--color-champagne)",
              "&:hover": {
                backgroundColor: "var(--color-desert-sun)",
              },
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
      {/* <Fade in={!trigger}>
        <Box
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            handleClick(event, Direction.Down)
          }
          role="presentation"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 15,
          }}
        >
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              // backgroundColor: "var(--color-champagne)",
              "&:hover": {
                backgroundColor: "var(--color-desert-sun)",
              },
            }}
          >
            <KeyboardArrowDownIcon />
          </Fab>
        </Box>
      </Fade> */}
    </>
  );
};

export default Chip;
