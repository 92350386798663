import { SocialMediaType } from "../types/enums";
import { RouteData, SocialMediaInformation } from "../types/shared.types";

export const Pages: RouteData[] = [
  {
    Name: "Home",
    Path: "/",
    Enabled: true,
  },
  {
    Name: "Latest",
    Path: "/blog",
    Enabled: false,
  },
  {
    Name: "Services",
    Path: "/services",
    Enabled: true,
  },
  {
    Name: "Gallery",
    Path: "/gallery",
    Enabled: false,
  },
  {
    Name: "About",
    Path: "/about-us",
    Enabled: true,
  },
  {
    Name: "Contact",
    Path: "/contact",
    Enabled: true,
  },
];

export const SocialMedia: SocialMediaInformation[] = [
  {
    Name: "Facebook",
    Type: SocialMediaType.Facebook,
    Path: "https://facebook.com/indierouge.nc",
  },
  {
    Name: "Instagram",
    Type: SocialMediaType.Instagram,
    Path: "https://www.instagram.com/indie_rouge",
  },
  {
    Name: "TikTok",
    Type: SocialMediaType.TikTok,
    //Icon: "/icons/tiktok-logo.svg",
  },
];
