import * as React from "react";
import { FunctionComponent } from "react";

import { Link, navigate } from "gatsby";

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
  Typography,
  styled,
} from "@mui/material";

import {
  CloseIcon,
  EmailTwoToneIcon,
  LocalPhoneTwoToneIcon,
  MenuIcon,
  PlaceTwoToneIcon,
} from "../../../../../../assets/icons";
import UIContext from "../../../../../../contexts/ui/UIContext";
import { Constants, FormattedAddress } from "../../../../../../data/constants";
import { Pages, SocialMedia } from "../../../../../../data/routes";
import { RouteData } from "../../../../../../types";
import { SocialMediaIcon } from "../../../../../icons";
import Logo from "../../../../../logo/logo.component";
import { NavBarWrapper, NavList, NavListItem } from "./navbar.styled";

const CustomLink = styled(Link)({}, ``);

const MobileNavBar = styled("div")(
  (props) => ({
    display: "none",
    [props.theme.breakpoints.only("sm")]: {
      display: "grid",
    },
    [props.theme.breakpoints.down("sm")]: {
      display: "grid",
    },
  }),
  ``
);

const NavLink = (page: RouteData) => {
  const isActive =
    typeof window !== "undefined" &&
    window.location.pathname.toLowerCase() === page.Path.toLowerCase();

  return (
    <ListItemButton
      onClick={() => navigate(page.Path)}
      sx={(theme: Theme) => ({
        transition: "box-shadow 0.3s ease",
        color: isActive ? "var(--color-gray-300)" : "var(--color-black)",
        justifyContent: "center",
        flexGrow: 1,
        height: "28px",
        fontFamily: "var(--font-bebas-neue)",
        "&.MuiListItemButton-root:hover": {
          //boxShadow: "0 0 1px rgba(33,33,33,0.2)",
          background: "transparent",
        },
        "&:hover": {
          color: "var(--color-desert-sun)",
        },
      })}
    >
      {page.Name.toUpperCase()}
    </ListItemButton>
  );
};

const Information = ({ text, icon, link }: InformationProps) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {icon && icon}
      <Typography variant="header" textAlign={"start"}>
        {link ? <a href={link}>{text}</a> : <>{text}</>}
      </Typography>
    </Stack>
  );
};

interface InformationProps {
  text: string;
  icon?: React.ReactNode;
  link?: string;
}

const NavBar: FunctionComponent = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const formattedAddress = FormattedAddress();

  const { isSmallScreen } = React.useContext(UIContext);

  const handleLinkClick = (path: string) => {
    handleDrawerToggle(false);
    navigate(path);
  };

  const handleDrawerToggle = (state?: boolean) => {
    setMobileOpen((prevState) => state ?? !prevState);
  };

  const drawer = (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        flexDirection: "column",
        flexGrow: 1,
      }}
      // onClick={handleDrawerToggle}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",

          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {/* <Stack
            direction={"row"}
            padding={"5 5 5 5"}
            margin={"10px"}
            justifyContent={"space-between"}
          > */}
        <div style={{ gridColumn: "2/3" }}>
          <Logo
            style={{
              position: "relative",
              width: 150,
              margin: 10,
            }}
          />
        </div>
        <div style={{ gridColumn: "3/4", position: "relative", right: "25px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => handleDrawerToggle()}
            sx={{
              mr: 2,
              display: { md: "none" },
              borderRadius: 1,
              margin: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {/* <IconButton
        style={{ position: "absolute", right: 0 }}
        onClick={() => handleDrawerToggle()}
      >
        <CloseIcon />
      </IconButton> */}
      {/* <Logo
        style={{
          position: "relative",
          width: 150,
          margin: 10,
        }}
      /> */}
      {/* <GatsbyImage
        image={logo?.file.childImageSharp.gatsbyImageData}
        alt="logo"
        style={{
          position: "relative",
          width: 150,
          margin: 10,
        }}
        loading="eager"
      /> */}
      <Divider />
      <List style={{ width: "100%" }}>
        {Pages.filter((i) => i.Enabled).map((page) => {
          const isActive =
            typeof window !== "undefined" &&
            window.location.pathname.toLowerCase() === page.Path.toLowerCase();
          return (
            <ListItem key={page.Name} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: "center",
                  color: isActive
                    ? "var(--color-gray-300)"
                    : "var(--color-black)",
                }}
                onClick={() => handleLinkClick(page.Path)}
              >
                <ListItemText
                  primary={page.Name}
                  primaryTypographyProps={{
                    style: { fontFamily: "var(--font-bebas-neue)" },
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem style={{ justifyContent: "center" }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <ListItemText
              primary={"Social Media"}
              primaryTypographyProps={{
                style: { fontFamily: "var(--font-bebas-neue)" },
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              alignContent="center"
              gap={1}
              style={{ flexWrap: "wrap", height: "20px", scale: "0.4" }}
            >
              {SocialMedia.map((media, index) => {
                return (
                  media.Path && (
                    <SocialMediaIcon
                      key={index}
                      index={index}
                      label={media.Name}
                      url={media.Path}
                      type={media.Type}
                    />
                    // <IconButton
                    //   key={index}
                    //   aria-label={socialMedia.Name}
                    //   style={{ borderRadius: 5 }}
                    //   onClick={() => {
                    //     window.open(socialMedia.Path, "_blank");
                    //   }}
                    // >
                    //   {socialMedia.Icon}
                    // </IconButton>
                  )
                );
              })}
            </Stack>
          </Stack>
        </ListItem>
      </List>
      <Stack
        style={{ justifyContent: "flex-end", marginBottom: 10, flexGrow: 1 }}
      >
        <Information
          text={`${Constants.PhoneNumber.Display}`}
          icon={
            <LocalPhoneTwoToneIcon
              fontSize="small"
              style={{
                alignSelf: "center",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            />
          }
          link={`tel:${Constants.PhoneNumber.Value}`}
        />
        <Information
          text="info@indierogue.com"
          icon={
            <EmailTwoToneIcon
              fontSize="small"
              style={{
                alignSelf: "center",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            />
          }
          link="mailto:info@indierouge.commailto:info@indierouge.com"
        />
        <Information
          text={formattedAddress}
          icon={
            <PlaceTwoToneIcon
              fontSize="small"
              style={{
                alignSelf: "center",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            />
          }
        />
      </Stack>
    </Box>
  );

  return (
    <NavBarWrapper>
      {/* {isSmallScreen ? ( */}
      <MobileNavBar
        style={{
          gridTemplateColumns: "1fr minmax(80svw, 0.85fr) 1fr",
          height: "100%",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {/* <Stack
            direction={"row"}
            padding={"5 5 5 5"}
            margin={"10px"}
            justifyContent={"space-between"}
          > */}
        <div style={{ gridColumn: "2/3" }}>
          <Logo
            style={{
              position: "relative",
              width: 150,
            }}
          />
        </div>
        <div style={{ gridColumn: "3/4", position: "relative", right: "25px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => handleDrawerToggle()}
            sx={{
              mr: 2,
              display: { md: "none" },
              borderRadius: 1,
              margin: 0,
            }}
          >
            <MenuIcon fontSize="medium" />
          </IconButton>
        </div>
        <Drawer
          //container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100svw",
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* </Stack> */}
      </MobileNavBar>

      <NavList>
        <Logo style={{ position: "relative", width: 150 }} />
        {Pages.filter((i) => i.Enabled).map((page, index) => {
          return <NavListItem key={index}>{NavLink(page)}</NavListItem>;
        })}
      </NavList>
    </NavBarWrapper>
  );
};

export default NavBar;
