import React, { useEffect } from "react";

import { SvgIcon } from "@mui/material";

import { GenerateIconId } from "../../utilities";
import { Debug } from "../../utilities/debug-utilities";

const FacebookIcon = () => {
  const [id, setId] = React.useState<string>("");

  useEffect(() => {
    const generatedId = GenerateIconId(FacebookIcon.name);
    Debug(`FacebookIcon:id`, generatedId);
    setId(generatedId);
  }, []);

  return (
    <SvgIcon style={{ minWidth: "42px", minHeight: "42px" }}>
      <svg
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath={`url(#clip0_56_${id})`}>
          <path
            d="M35.7 0.5H6.3C2.82061 0.5 0 3.32061 0 6.8V36.2C0 39.6794 2.82061 42.5 6.3 42.5H35.7C39.1794 42.5 42 39.6794 42 36.2V6.8C42 3.32061 39.1794 0.5 35.7 0.5Z"
            fill="#3B5998"
          />
          <path
            d="M23.543 37.9062V13.3789C23.543 11.6562 24.0352 10.5078 26.4141 10.5078H29.5312V5.33984C28.957 5.25781 27.1523 5.09375 25.0195 5.09375C20.5898 5.09375 17.5547 7.80078 17.5547 12.8047V37.9062M29.2852 17.0703H12.4688V22.9766H28.5469"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id={`clip0_56_${id}`}>
            <rect
              width="42"
              height="42"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default FacebookIcon;
